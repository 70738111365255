import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import './css/Milestone.css';

export const Milestone = () => {
  return (
    <div className="milestone-container">
      <h1 className="milestone-title">Career Milestones</h1>
      <div className="timeline">
        {/* Milestone 1: Successful Project Create */}
        <div className="milestone">
          <div className="milestone-icon success"></div>
          <div className="milestone-content">
            <h3>Successful Project Create</h3>
            <p>Completed and launched 20+ successful apps on Play Store and Apple Store.</p>
          </div>
        </div>

        {/* Milestone 2: Basis ICT Winner */}
        <div className="milestone">
          <div className="milestone-icon winner"></div>
          <div className="milestone-content">
            <h3>Basis ICT Winner</h3>
            <p>My project "Salebee" won the prestigious Basis ICT award in 2023 for innovation and performance.</p>
          </div>
        </div>

        {/* Milestone 3: API Development */}
        <div className="milestone">
          <div className="milestone-icon api"></div>
          <div className="milestone-content">
            <h3>API Development</h3>
            <p>Developed and optimized several APIs for various applications.</p>
          </div>
        </div>

        {/* Milestone 4: React JS Development */}
        <div className="milestone">
          <div className="milestone-icon react"></div>
          <div className="milestone-content">
            <h3>React JS Development</h3>
            <p>Mastered React JS and developed dynamic web applications with modern tools.</p>
          </div>
        </div>

        {/* Milestone 5: Started Full Stack Development */}
        <div className="milestone">
          <div className="milestone-icon fullstack"></div>
          <div className="milestone-content">
            <h3>Started Full Stack Development</h3>
            <p>Successfully completed full-stack projects handling both frontend and backend development, UI/UX, database design, business logic, and API development.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
