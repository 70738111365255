//import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import React from 'react';
import './css/Contactinfo.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
 


  const whatsappNumber = '+8801782084390'; // Replace with your WhatsApp number
  const email = 'ridoyfahim92@gmail.com'; // Replace with your email
  

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                <div className="contact-info-items">
     
      <div className="contact-item">
        <label>WhatsApp:</label>
        <a href={`https://wa.me/${whatsappNumber}`} target="_blank" rel="noopener noreferrer">
          {whatsappNumber}
        </a>
      </div>
      <div className="contact-item">
        <label>Email:</label>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )

  
}





