
import { Table, } from 'react-bootstrap'; 

import 'react-multi-carousel/lib/styles.css';

import 'bootstrap/dist/css/bootstrap.min.css';


export const Skills = () => {
  

  const skillsList = [
    { skill: "Flutter", experience: "5+ years of experience in developing cross-platform mobile apps." },
    { skill: "Dart", experience: "Experienced in Dart programming language used with Flutter." },
    { skill: "Firebase", experience: "Proficient in using Firebase for backend services, authentication, and notifications. I implemented firebase for Flutter and laravel as well." },
    { skill: "REST APIs", experience: "Experienced in integrating RESTful APIs for communication between mobile apps and backend." },
    { skill: "Notification", experience: "Experienced in handling In App Notification, Push notification from frontend and backend." },
    { skill: "Google Map", experience: "Got a brief knowledge on google map and the usages of it for many purposes." },
    { skill: "BLoC", experience: "In-depth understanding and experience with the BLoC state management pattern." },
    { skill: "Getx", experience: "Proficient in using Getx for state management in Flutter apps." },
    { skill: "Provider", experience: "Skilled in using Provider for managing state in Flutter applications." },
    { skill: "Api Development", experience: "Already developed 3 projects with my own API and made almost 70+ APIs till now." },
    { skill: "Laravel", experience: "I started my career with Laravel, took a brief pause, and later returned to backend development, deepening my expertise in building scalable applications." },
    { skill: "Native Js", experience: "Currently I am developing all my websites with React js. The site you are looking at right now is also developed on React js." },
    { skill: "Database and Security", experience: "In some of my projects, I tackled challenges related to database management and security, which expanded my knowledge in these areas." },
    // Add more skills as needed
  ];

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>Over the years, I have dedicated myself to constantly learning and evolving in the fast-paced world of technology.<br />These are the skills that have empowered me to build, create, and innovate.</p>

              <Table striped bordered hover responsive className="skills-table">
                <thead>
                  <tr>
                    <th>Skill</th>
                    <th>Experience</th>
                  </tr>
                </thead>
                <tbody>
                  {skillsList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.skill}</td>
                      <td>{item.experience}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    
    </section>
  );
};
