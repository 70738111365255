import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import payplusImage from "../assets/img/project-img-payplus.png";
import jaygaImage from "../assets/img/project-img-jayga.png";
import myzooImage from "../assets/img/project-img-myzoo.png";
import salebeeImage from "../assets/img/project-img-salebee.png";
import holytuneImage from "../assets/img/project-img-holytune.png";
import hisabeeImage from "../assets/img/project-img-hisabee.png";

import 'animate.css';
import './css/Project.css';
import './css/experience.css';

import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const experiences = [
    { company: 'Right Brain Solution', details: 'Started my job as a laravel developer here and later explored the flutter world from here also',
       time: "2019 april -2020 may" },
    { company: 'Advanced App Bangladesh', details: 'Joined 14 flutter devs in this company. We got seveal projects to enhance and boost our skills on flutter.' ,
      time: "2020 june -2021 December"},
    { company: 'Hishabee', details: 'Led a team of developers in creating mobile apps for iOS and Android.',
      time: "2022 january -2022 Aril" },
    { company: 'SaleBee', details: 'Led a team of developers in creating mobile apps for iOS and Android.',
      time: "2022 may -2023 January" },
    { company: 'Pay Station', details: 'Led a team of developers in creating mobile apps for iOS and Android.' ,
      time: "2023 February - Currently Working Here"},
  ];
  const projects = [
    {
      title: "Payplus Agent",
      description: "This is one of a successful project in Bangladesh right now. This app handle 60-70 lacs taka per day. I developed this App without taking any assistance.",
      imgUrl: payplusImage,
      link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
    {
      title: "Holytune",
      description: "This is the first commercial islamic music app in bangladesh. I have got 1 lac download from playstore in the first month of app release.",
      imgUrl: holytuneImage,
       link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
    {
      title: "SaleBee",
      description: "One of the best CRM system in bangnladesh right now. We have got the BASIS ICT winner award of 2023 for this project. and The SaleBee CRM App is developed only by me.",
      imgUrl: salebeeImage,
       link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
    {
      title: "Hishabee",
      description: "A well known startup project in Bangladesh. I handled the App for them.",
      imgUrl: hisabeeImage,
       link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
    {
      title: "MyZoo",
      description: "A big scale multivendor E-commerce app. I developed and handled three app for this project. User, Merchant and Delivery App. Also I leaded the backend development here also and I developed many api here.",
      imgUrl: myzooImage,
       link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
    {
      title: "Jayga",
      description: "I am the lead developer and handled all of the side here. Developed Android and iOS app , Made database model and structure , developed api , modified backened as needed for me.",
      imgUrl: jaygaImage,
       link: "https://play.google.com/store/search?q=payplus+agent&c=apps"
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 className="title-label">Projects & experiences</h2>
       

                <div className="experience-table">
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Details</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((exp, index) => (
            <tr key={index}>
              <td className="td-label">{exp.company}</td>
              <td className="td-label">{exp.details}</td>
              <td className="td-label">{exp.time}</td>
            </tr>
          ))}
        </tbody>
      </table>


      </div>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Top Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Mentioned Project</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Contribution</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
     
    </section>
  )
}
