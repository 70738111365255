import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/poster.png";

import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import './Style.css';

import TrackVisibility from 'react-on-screen';


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Developer", "Android Developer", "iOS Developer", "API Developer" ];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
   
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Flutter+Laravel+ReactJS DEV</span>
                <h2 className="title-label">{`Hi! I'm Mir Fahim Rahman -`} <span className="txt-rotate" dataPeriod="100" data-rotate='[ "Web Developer", "Backend Developer", "Android Developer" "iOS developer ]'><span className="wrap">{text}</span></span></h2>
                <p>I am a <span className="inline-text-green">lead flutter developer</span> in my current company with more than <span className="inline-number">5</span> years of experience. I have been working with flutter since its <span className="inline-text">alpha stage</span>. I have developed almost <span className="inline-number">25+</span> Industry level apps till now and contributed over 50+ apps.
Currently, I am working on a recharge and payment gateway system.
I developed an app named PayPlus Agent which handles 40-60 lacs taka per day.
I have good experiences on flutter with the knowledge of BLoC, Provider, Getx, Firebase, and REST APIs, Push and Local notifications. Most importantly, I have core knowledge and experience of the Dart language. Because of these, I can make any of your dream projects come true in the <span className="inline-text">best way possible</span> .</p>

          
              </div>}
            </TrackVisibility>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
               
               
                <p> <span className="inline-text">Also I can handle API development with the help of laravel.
                I made 70+ api and completed a social media project within one month.</span>  .</p>

                  <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
         
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

